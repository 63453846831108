<template>
	<div v-if="dataInfo.title" class="news-detail" v-title="dataInfo.title+'——千家美装饰室内装修公司_房屋装修设计_家装设计报价'">
		<!-- 头部导航栏 -->
		<NavBar></NavBar>
        <div class="box">
			<img class="bran" src="../image/about/news_banner.jpg" alt="" />
		</div>
        <div class="box-mob">
            <img
				class="bran"
				src="../image/about/news_banner.jpg"
				alt=""
			/>
		</div>
        <div class="content ">
			<div class="left f-y-s-c">
                <div class="info f-y-s-c">
                   <span class="title eps2">{{dataInfo.title}}</span>
                   <div class="desc f-x-b-c">
                        <span class="text1"></span>
                        <span class="text2">发布时间：{{dataInfo.createTime && dataInfo.createTime.slice(0,10)}}</span>
                   </div>
                   <div class="content1" v-html="dataInfo.content"></div>
                   <div class="options f-x-c-c">
                        <div @click="navToNewsDetail(preBtnInfo.id)" v-if="preBtnInfo.show" class="btn ">上一篇:{{preBtnInfo.title}}</div>
                        <div @click="navToNewsDetail(nextBtnInfo.id)" v-if="nextBtnInfo.show" class="btn ">下一篇：{{nextBtnInfo.title}}</div>
                   </div>
                </div>
                <div class="activity">
                    <Activity ></Activity>
                </div>
            </div>
            <div class="right">
                <div class="from">
                    <VisitorForm title="免费装修报价" desc="我家按本案例装修需要多少钱？" adUnitCode="pwKs"></VisitorForm>
                </div>
                <div v-if="otherCaseList.length" class="recommend" ref="refRecommend">
                        <span class="text4">热门装修案例</span>
                        <div
                            v-for="(item,index) in otherCaseList.slice(0,3)"
                            :key="index" 
                            :class="index==0? 'no-border':''"
                            :style="`{border-top:}`"
                            class="recommend-item"
                            @click="navToDetail(item.id)"
                            >
                            <img class="img" :src="item.coverUrl" alt="">
                            <div class="text1 eps1">{{item.villageName}}</div>
                        </div>
                </div>
                <transition name="el-fade-in"  mode="out-in">
                    <div v-if="showFixedForm" class="from-fixed">
                        <VisitorForm title="免费装修报价" desc="我家按本案例装修需要多少钱？" adUnitCode="pwKs"></VisitorForm>
                    </div>
                </transition>
            </div>
		</div>
		<!-- 底部 -->
		<Footer></Footer>

        <!-- 右浮窗和底部吸顶 -->
        <Floating></Floating>
        <FloatingMob></FloatingMob>
        <VisitorForm3></VisitorForm3>
        <Tabbar></Tabbar>
	</div>
</template>
<script>
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import HouseInfo from '../components/HouseInfo.vue'
import Activity from '../components/Activity.vue'
import VisitorForm from '../components/VisitorForm.vue'
import { getNewsDetail } from '@/api/news.js'
import { useNewsDict,useCaseDict } from '@/utils/frequent.js'

export default {
	components: {
		NavBar,
		Footer,
        HouseInfo,
        Activity,
        VisitorForm
	},
	data() {
		return {
            otherCaseList:[],
            curId:'',
            dataInfo:{},
            newsDict:[],
            preBtnInfo:{
                show:false,
            },
            nextBtnInfo:{
                show:false,
            },
            params:{},
            showFixedForm:false,
		}
	},
    async mounted(){
        this.curId = this.$route.query.id
        const {salesRoomNo,houseType,designStyle} = this.$route.query
        const params1 = {
            size:500,
            current:1,
        }
        const params2 = {
            size:500,
            current:1,
            isRecommend:1
        }
        if(this.curCityInfo.value=='00'){
            this.newsDict = (await useNewsDict(params1)).filter(item=>item.isHeadOffice ==1)
            this.otherCaseList = (await useCaseDict(params2)).filter(item=>item.isHeadOffice ==1)
        }else{
            this.newsDict = (await useNewsDict(params1)).filter(item=>item.areaCode ==this.curCityInfo.value)
            this.otherCaseList = (await useCaseDict(params2)).filter(item=>item.areaCode ==this.curCityInfo.value)
        }
        
        this.getDetailData()
        this.setBtnData()

        window.addEventListener("scroll", this.scrollToTop);

     
    },
	methods: {
        scrollToTop(){
            const scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            let height = 1300
            if(this.$refs.refRecommend){
                const recHeight =  this.$refs.refRecommend.clientHeight
                height += recHeight
            }
            if (scrollTop >= height) {
                this.showFixedForm = true;
            } else  {
                this.showFixedForm = false;
            }
        },
        navToNewsDetail(id){
            if(id=='-1') return 
            this.$router.replace({
                path:'/about/news-detail',
                query:{
                    id
                }
            })
        },
        navToDetail(id){
            this.$router.push({
                path:'/investor/detail',
                query:{
                    id
                }
            })
        },
        setBtnData(){
            const index = this.newsDict.findIndex(item=>item.articleId==this.curId)
            if(index!=-1){
                if(index!=0){
                    this.preBtnInfo = {
                        id:this.newsDict[index-1].articleId,
                        title:this.newsDict[index-1].title,
                        show:true
                    }
                }else{
                    this.preBtnInfo = {
                        id:-1,
                        title:'无',
                        show:true
                    }
                }
                if(index!=(this.newsDict.length-1)){
                    this.nextBtnInfo = {
                        id:this.newsDict[index+1].articleId,
                        title:this.newsDict[index+1].title,
                        show:true
                    }
                }else{
                    this.nextBtnInfo = {
                        id:-1,
                        title:'无',
                        show:true
                    }
                }
                
            }
        },
        async getDetailData(){
            try{
                const res = await getNewsDetail(this.curId)
                if(res.code==0){
                    this.dataInfo = res.data
                }
            }catch(err){
                console.log(err);
            }
        },
     
    },
    beforeDestroy(){
        window.removeEventListener("scroll",this.scrollToTop)
    }
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .news-detail {
        overflow-x: hidden;
        .box-mob{
            width: 100%;
			height: 200px;
			margin-top: 43px;
			.bran {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
        }
        .box{
            display: none;
        }
		.bran {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.content {
			padding: 0 10px;
           
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
            flex-flow: column;
			justify-content: center;
			background: #ffffff;
            .left{
                width: 100%;
                margin-top: 50px;
                .info{
                    width: 100%;
                    .title{
                        
                        font-weight: bold;
                        font-size: 20px;
                        color: #241934;
                        line-height: 30px;
                        text-align: center;
                        width: 80%;
                    }
                    .desc{
                        margin-top: 10px;
                        .text1{
                            
                            font-weight: 400;
                            font-size: 12px;
                            color: #241934;
                            margin-right: 20px;
                        }
                        .text2{
                            
                            font-weight: 400;
                            font-size: 12px;
                            color: #241934;
                        }
                    }
                    .content1{
                        width: 100%;
                        font-size: 13px;
                        margin-top: 20px;
                        
                        /deep/ p,/deep/ strong,/deep/ span{
                            font-size: 13px;
                            
                        }
                        /deep/img{
                            max-width: 100%;
                        }
                        /deep/ .ql-align-right{
                            text-align: right;
                        }
                        /deep/ .ql-align-center{
                            text-align: center;
                        }
                    }
                    .options{
                        margin-top: 30px;
                        width: 100%;
                        .btn:first-child{
                            margin-right: 10px;
                        }
                        .btn{
                            cursor: pointer;
                            width: 160px;
                            height: 35px;
                            line-height: 35px;
                            background: #F5F4F4;
                            border-radius: 20px;
                            border: 1px solid #E5E5E5;

                            
                            font-weight: 400;
                            font-size: 12px;
                            color: #221732;
                            padding: 0 10px;
                            box-sizing: border-box;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            text-align: center;
                        }
                    }
                   
                        
                }
                .activity{
                    margin-top: 50px;
                    width: 100%;
                }
            }
            .right{
                width: 100%;
                margin-top: 50px;
                .from{
                    width: 100%;
                    // padding: 0 40px;
                    box-sizing: border-box;
                }
                .from-fixed{
                    display: none;
                }
                .recommend{
                    display: flex;
                    flex-flow: column;
                    width: 100%;
                    background: #FFFFFF;
                    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.16);
                    border-radius: 5px;
                    margin-top: 50px;
                    margin-bottom: 30px;
                    padding: 0 10px;
                    box-sizing: border-box;
                    .text4{
                        
                        font-weight: bold;
                        font-size: 20px;
                        color: #140626;
                        line-height: 20px;
                        margin: 15px auto 0px auto;
                    }
                    .no-border{
                        border: none !important;
                    }
                    .recommend-item{
                        padding: 20px 0 15px 0;
                        box-sizing: border-box;
                        width: 100%;
                        height: 250px;
                        position: relative;
                        border-top: 1px solid #E5E5E5;
                        display: flex;
                        flex-flow: column;
                        justify-content: space-between;
                        .img{
                            width: 100%;
                            height: 190px;
                            border-radius: 2px;
                            object-fit: cover;
                        }
                        .text1{
                            
                            font-weight: 400;
                            font-size: 13px;
                            color: #140626;
                            line-height:15px;
                            margin-top: 5px;
                            width: 100%;
                        }
                    }
                }
            }
           
		}
	}
}
@media screen and (min-width: 900px) and(max-width:1359px) {
	.news-detail {
		height: 100%;
        .box-mob{
            display: none;
        }
        .box{
            width: 100%;
			height: 420px;
			// margin-top: 96px;
        }
		.bran {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.content {
			padding: 0 2% 60px 2%;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: stretch;
			justify-content: center;
			background: #ffffff;
            .left{
                // width: 1020px;
                flex: 1;
                margin-right: 67px;
                margin-top: 60px;
                .info{
                    width: 100%;
                    .title{
                        
                        font-weight: bold;
                        font-size: 48px;
                        color: #241934;
                        line-height: 65px;
                        text-align: center;
                        width: 80%;
                    }
                    .desc{
                        margin-top: 40px;
                        .text1{
                            
                            font-weight: 400;
                            font-size: 24px;
                            color: #241934;
                            margin-right: 50px;
                        }
                        .text2{
                            
                            font-weight: 400;
                            font-size: 24px;
                            color: #241934;
                        }
                    }
                    .content1{
                        width: 100%;
                        font-size: 14px;
                        margin-top: 65px;
                        
                        /deep/ p,/deep/ strong,/deep/ span{
                            font-size: 20px;
                            
                        }
                        /deep/img{
                            max-width: 100%;
                        }
                        /deep/ .ql-align-right{
                            text-align: right;
                        }
                        /deep/ .ql-align-center{
                            text-align: center;
                        }
                        
                    }
                    .options{
                        margin-top: 70px;
                        width: 100%;
                        .btn:first-child{
                            margin-right: 30px;
                        }
                        .btn{
                            cursor: pointer;
                            width: 458px;
                            height: 80px;
                            line-height: 80px;
                            background: #F5F4F4;
                            border-radius: 40px;
                            border: 1px solid #E5E5E5;

                            
                            font-weight: 400;
                            font-size: 30px;
                            color: #221732;
                            padding: 0 38px;
                            box-sizing: border-box;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            text-align: center;
                        }
                    }
                   
                        
                }
                .about{
                    width: 100%;
                    margin-top: 60px;
                    .title{
                        width: 100%;
                        .text1{
                            
                            font-weight: bold;
                            font-size: 36px;
                            color: #241934;
                            line-height: 36px;
                            margin-right: 20px;
                        }
                        .cus-line{
                            flex: 1;
                            height: 2px;
                            background: #6C1EC8;
                        }
                    }
                    .list{
                        margin-top: 33px;
                        width: 100%;
                        display: grid;
                        justify-content: space-between;
                        grid-template-columns: repeat(auto-fill,49%);
                        .item{
                            margin-bottom: 40px;
                            width: 100%;
                            height:320px ;
                            box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.11);
                            border-radius: 10px;
                            overflow: hidden;
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                            .img{
                                width: 185px;
                                height: 185px;
                                opacity: 0.8;
                            }
                            .text2{
                                margin-top: 30px;
                                width: 100%;
                                height: 80px;
                                background: rgba(0, 0, 0, 0.5);
                                border-radius: 0 0 10px 10px;
                                
                                font-weight: 400;
                                font-size: 30px;
                                color: #FFFFFF;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                box-sizing: border-box;
                                padding: 0 30px;
                                text-align: center;
                                line-height: 80px;
                            }
                            
                        }
                    }
                }
                .activity{
                    margin-top: 60px;
                    width: 100%;
                }
            }
            .right{
                width: 469px;
                // height: 800px;
                margin-top: 60px;
                .info{
                    width: 100%;
                    height: 426px;
                    background: #FFFFFF;
                    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.16);
                    border-radius: 10px;
                    padding: 30px;
                    box-sizing: border-box;
                    .title{
                        
                        font-weight: bold;
                        font-size: 36px;
                        color: #140626;
                    }
                    .des-list{
                        margin-top:28px;
                        width: 100%;
                        .item{
                            .des-img{
                                width: 194px;
                                height: 194px;
                                border-radius: 50%;
                            }
                            .text1{
                                
                                font-weight: bold;
                                font-size: 30px;
                                color: #140626;
                                margin-top: 23px;
                            }
                            .text2{
                                
                                font-weight: 400;
                                font-size: 28px;
                                color: #5D5665;
                                margin-top: 13px;
                            }
                        }
                    }
                }
                .from{
                    width: 100%;
                }
                .from-fixed{
                    width: 100%;
					// margin-top: 60px;
                    position: sticky;
                    top: 30px;
                }
                .recommend{
                    display: flex;
                    flex-flow: column;
                    width: 100%;
                    background: #FFFFFF;
                    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.16);
                    border-radius: 10px;
                    margin-top: 60px;
                    padding: 0 30px;
                    box-sizing: border-box;
                    .text4{
                        
                        font-weight: bold;
                        font-size: 36px;
                        color: #140626;
                        line-height: 36px;
                        margin: 30px auto 0px auto;
                    }
                    .no-border{
                        border: none !important;
                    }
                    .recommend-item{
                        padding: 40px 0;
                        box-sizing: border-box;
                        width: 100%;
                        // height: 490px;
                        position: relative;
                        border-top: 1px solid #E5E5E5;
                        .img{
                            width: 410px;
                            height: 297px;
                            border-radius: 4px;
                        }
                        .text1{
                            
                            font-weight: 400;
                            font-size: 30px;
                            color: #140626;
                            line-height: 30px;
                            margin-top: 16px;
                            width: 100%;
                        }
                    }
                }
            }
           
		}
	}
}
@media screen and (min-width: 1360px) {
	.news-detail {
		height: 100%;
        .box-mob{
            display: none;
        }
        .box{
            width: 100%;
			height: 420px;
			// margin-top: 96px;
        }
		.bran {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.content {
			padding: 0 180px 60px 180px;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: stretch;
			justify-content: center;
			background: #ffffff;
            .left{
                width: 1020px;
                margin-right: 67px;
                margin-top: 60px;
                .info{
                    width: 100%;
                    .title{
                        
                        font-weight: bold;
                        font-size: 48px;
                        color: #241934;
                        line-height: 65px;
                        text-align: center;
                        width: 80%;
                    }
                    .desc{
                        margin-top: 40px;
                        .text1{
                            
                            font-weight: 400;
                            font-size: 24px;
                            color: #241934;
                            margin-right: 50px;
                        }
                        .text2{
                            
                            font-weight: 400;
                            font-size: 24px;
                            color: #241934;
                        }
                    }
                    .content1{
                        width: 100%;
                        font-size: 14px;
                        margin-top: 65px;
                        
                        /deep/ p,/deep/ strong,/deep/ span{
                            font-size: 20px;
                            
                        }
                        /deep/img{
                            max-width: 100%;
                        }
                        /deep/ .ql-align-right{
                            text-align: right;
                        }
                        /deep/ .ql-align-center{
                            text-align: center;
                        }
                        
                    }
                    .options{
                        margin-top: 70px;
                        width: 100%;
                        .btn:first-child{
                            margin-right: 30px;
                        }
                        .btn{
                            cursor: pointer;
                            width: 458px;
                            height: 80px;
                            line-height: 80px;
                            background: #F5F4F4;
                            border-radius: 40px;
                            border: 1px solid #E5E5E5;

                            
                            font-weight: 400;
                            font-size: 30px;
                            color: #221732;
                            padding: 0 38px;
                            box-sizing: border-box;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            text-align: center;
                        }
                    }
                   
                        
                }
                .about{
                    width: 100%;
                    margin-top: 60px;
                    .title{
                        width: 100%;
                        .text1{
                            
                            font-weight: bold;
                            font-size: 36px;
                            color: #241934;
                            line-height: 36px;
                            margin-right: 20px;
                        }
                        .cus-line{
                            flex: 1;
                            height: 2px;
                            background: #6C1EC8;
                        }
                    }
                    .list{
                        margin-top: 33px;
                        width: 100%;
                        display: grid;
                        justify-content: space-between;
                        grid-template-columns: repeat(auto-fill,49%);
                        .item{
                            margin-bottom: 40px;
                            width: 100%;
                            height:320px ;
                            box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.11);
                            border-radius: 10px;
                            overflow: hidden;
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                            .img{
                                width: 185px;
                                height: 185px;
                                opacity: 0.8;
                            }
                            .text2{
                                margin-top: 30px;
                                width: 100%;
                                height: 80px;
                                background: rgba(0, 0, 0, 0.5);
                                border-radius: 0 0 10px 10px;
                                
                                font-weight: 400;
                                font-size: 30px;
                                color: #FFFFFF;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                box-sizing: border-box;
                                padding: 0 30px;
                                text-align: center;
                                line-height: 80px;
                            }
                            
                        }
                    }
                }
                .activity{
                    margin-top: 60px;
                    width: 100%;
                }
            }
            .right{
                width: 469px;
                // height: 800px;
                margin-top: 60px;
                .info{
                    width: 100%;
                    height: 426px;
                    background: #FFFFFF;
                    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.16);
                    border-radius: 10px;
                    padding: 30px;
                    box-sizing: border-box;
                    .title{
                        
                        font-weight: bold;
                        font-size: 36px;
                        color: #140626;
                    }
                    .des-list{
                        margin-top:28px;
                        width: 100%;
                        .item{
                            .des-img{
                                width: 194px;
                                height: 194px;
                                border-radius: 50%;
                            }
                            .text1{
                                
                                font-weight: bold;
                                font-size: 30px;
                                color: #140626;
                                margin-top: 23px;
                            }
                            .text2{
                                
                                font-weight: 400;
                                font-size: 28px;
                                color: #5D5665;
                                margin-top: 13px;
                            }
                        }
                    }
                }
                .from{
                    width: 100%;
                }
                .from-fixed{
                    width: 100%;
					// margin-top: 60px;
                    position: sticky;
                    top: 30px;
                }
                .recommend{
                    display: flex;
                    flex-flow: column;
                    width: 100%;
                    background: #FFFFFF;
                    box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.16);
                    border-radius: 10px;
                    margin-top: 60px;
                    padding: 0 30px;
                    box-sizing: border-box;
                    .text4{
                        
                        font-weight: bold;
                        font-size: 36px;
                        color: #140626;
                        line-height: 36px;
                        margin: 30px auto 0px auto;
                    }
                    .no-border{
                        border: none !important;
                    }
                    .recommend-item{
                        padding: 40px 0;
                        box-sizing: border-box;
                        width: 100%;
                        // height: 490px;
                        position: relative;
                        border-top: 1px solid #E5E5E5;
                        .img{
                            width: 410px;
                            height: 297px;
                            border-radius: 4px;
                        }
                        .text1{
                            
                            font-weight: 400;
                            font-size: 30px;
                            color: #140626;
                            line-height: 30px;
                            margin-top: 16px;
                            width: 100%;
                        }
                    }
                }
            }
           
		}
	}
}
</style>
